<template>
  <modal class-name="w-full sm:px-8 sm:w-400px" ref="repaymentCard">
    <h3 class="text-26 font-black">Pay with</h3>
    <p class="text-12 mt-2 font-normal text-marketplace-blue text-opacity-50">
      Select a card to pay now.
    </p>
    <div class="grid grid-cols-1 gap-4 mb-3 mt-8" style="letter-spacing: .1em">
      <template v-for="(card, index) in cards">
        <div
          :key="index"
          class="flex flex-col"
          @click.prevent="selectCard(card)"
        >
          <div class="flex justify-between mb-4 cursor-pointer">
            <h5
              class="text-10 md:text-14 font-normal text-gray-800 inline-flex items-center"
            >
              <img
                :src="getProvider(card.card_type).logo"
                alt="Visa"
                style="height: 10px"
              />
              <span
                class="ml-5 text-gray-700"
                v-html="`•••• &nbsp;${card.last4}`"
              ></span>
            </h5>
            <div
              class="rounded-full justify-center items-center flex p-1"
              :class="
                selectedCard === card
                  ? `bg-${getProvider(card.card_type).color}-500`
                  : 'border border-gray-300'
              "
            >
              <ion-icon
                name="checkmark-outline"
                class="text-white text-lg"
              ></ion-icon>
            </div>
          </div>
          <div
            class="w-full"
            style="opacity: 0.04; border: 1px solid #242D4F;"
          ></div>
        </div>
      </template>
      <div class="mb-4 cursor-pointer">
        <paystack
          :amount="amountToPay"
          :callback="payWithNewCard"
          type="button"
          class="focus:outline-none"
        >
          <h5
            class="text-10 md:text-14 font-normal text-gray-800 inline-flex items-center"
          >
            <svg
              width="35"
              height="23"
              viewBox="0 0 35 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="35" height="23" rx="3" fill="#242D4F" />
              <circle opacity="0.8" cx="14.5" cy="11.5" r="5.5" fill="white" />
              <circle opacity="0.5" cx="20.5" cy="11.5" r="5.5" fill="white" />
            </svg>
            <span class="ml-5 text-gray-700">New card</span>
          </h5>
        </paystack>
      </div>
    </div>

    <div class="mt-8">
      <button
        type="button"
        class="btn btn-blue py-6 w-full"
        @click="payWithRepaymentCard"
        :disabled="!selectedCard"
      >
        Continue - Pay Now {{ amountToPay | formatAmount }}
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    cards: {
      type: Array,
      default: () => []
    },
    amountToPay: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      selectedCard: null,
      providers: {
        mastercard: {
          logo: require("@/assets/mastercard.svg"),
          color: "red"
        },
        visa: {
          logo: require("@/assets/visa-blue.svg"),
          color: "blue"
        },
        default: {
          logo: require("@/assets/credit-card.svg"),
          color: "gray"
        }
      }
    };
  },
  methods: {
    close() {
      this.$refs.repaymentCard.close();
    },
    open() {
      this.$refs.repaymentCard.open();
    },
    selectCard(card) {
      this.selectedCard = card;
    },
    getProvider(cardType) {
      switch (cardType?.toLowerCase()?.trim()) {
        case "mastercard":
        case "mastercard credit":
        case "mastercard debit":
          return this.providers.mastercard;
        case "visa":
        case "visa credit":
        case "visa debit":
          return this.providers.visa;
        default:
          return this.providers.default;
      }
    },
    payWithRepaymentCard() {
      this.$emit(
        "pay-with-repayment-card",
        this.selectedCard.authorization_code
      );
      this.$refs.repaymentCard.close();
    },
    payWithNewCard(data) {
      this.$emit("pay-with-new-card", data);
      this.$refs.repaymentCard.close();
    }
  }
};
</script>
